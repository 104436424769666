<template>
  <div>
    <CContainer class="meeting-details">
      <CRow>
        <CCol sm="3" col="4"> Topic </CCol>
        <CCol sm="9" col="8">
          {{ meeting.meetingtopic }}
        </CCol>
      </CRow>
      <CRow>
        <CCol sm="3" col="4"> Date </CCol>
        <CCol sm="9" col="8">
          {{ moment(meeting.meeting_date).format("LLL") }}
        </CCol>
      </CRow>
      <CRow>
        <CCol sm="3" col="4"> Created At </CCol>
        <CCol sm="9" col="8">
          {{ moment(meeting.createdAt).format("LLL") }}
        </CCol>
      </CRow>
      <CRow>
        <CCol sm="3" col="4"> Recorded </CCol>
        <CCol sm="9" col="8">
          <span v-if="meeting.recording === true" style="color: green">
            Yes
          </span>
          <span v-else style="color: red"> No </span>
        </CCol>
      </CRow>
      <CRow>
        <CCol sm="3" col="4"> Ended </CCol>
        <CCol sm="9" col="8">
          <span v-if="meeting.meeting_ended === true" style="color: green">
            Yes
          </span>
          <span v-else style="color: red"> No </span>
        </CCol>
      </CRow>
      <CRow>
        <CCol sm="3" col="4"> ID </CCol>
        <CCol sm="9" col="8">
          {{ meeting._id }}
        </CCol>
      </CRow>
      <CRow>
        <CCol sm="3" col="4"> Users </CCol>
        <CCol sm="9" col="8">
          {{ user_data.length }}
        </CCol>
      </CRow>
      <CRow>
        <CCol sm="3" col="4"> Created By </CCol>
        <CCol sm="9" col="8">
          <div class="meeting-raised-by-st">
            <span style="font-weight: 500"> Name: </span>
            {{
              meeting.created_by.username
                ? meeting.created_by.username
                : "Anonymous User"
            }}
            <br />
            <span style="font-weight: 500"> Email: </span>
            {{
              meeting.created_by.email
                ? meeting.created_by.email
                : "Email not provided"
            }}
            <br />
            <span style="font-weight: 500"> Number: </span>
            {{
              meeting.created_by.phoneSearch
                ? meeting.created_by.phoneSearch
                : "Number not provided"
            }}
          </div>
        </CCol>
      </CRow>
    </CContainer>
    <br />
    <CContainer class="meeting-details">
      <div v-if="user_data.length === 0" class="meeting-table">
        <CContainer style="font-weight: 500; color: red">
          No users have joined this meeting!
        </CContainer>
      </div>
      <div v-else>
        <CDataTable
          :items="user_data"
          :fields="fields"
          hover
          @row-clicked="rowClickHandler"
          id="user_table"
        >
          <template #username="{ item }">
            <td>
              <h6>
                {{ item.username ? item.username : "Not provided" }}
              </h6>
            </td>
          </template>
          <template #email="{ item }">
            <td>
              <h6>
                {{ item.email ? item.email : "Not provided" }}
              </h6>
            </td>
          </template>
          <template #phoneSearch="{ item }">
            <td>
              <h6>
                {{ item.phoneSearch ? item.phoneSearch : "Not provided" }}
              </h6>
            </td>
          </template>
          <template #availability="{ item }">
            <td>
              <h6>
                {{ item.availability ? item.availability : "Not provided" }}
              </h6>
            </td>
          </template>
        </CDataTable>
      </div>
    </CContainer>
  </div>
</template>

<script>
import MeetingServiceApi from "@/services/api/meeting";
export default {
  data() {
    return {
      user_data: [],
      fields: [
        { key: "username", label: "Name" },
        { key: "email", label: "Email" },
        { key: "phoneSearch", label: "Number" },
        { key: "availability", label: "Available" },
      ],
      id: this.$route.params.id,
      meeting: null,
    };
  },
  mounted() {
    this.getMeetingDetails();
  },
  methods: {
    getMeetingDetails() {
      MeetingServiceApi.getMeetingDetails(this.id).then((res) => {
        this.user_data = res.data.users_joined;
        this.meeting = res.data;
      });
    },
    rowClickHandler(item, index, column, event) {
      const id = item._id;
      this.$router.push({ name: "User details", params: { id } });
    },
  },
};
</script>

<style scoped>
.meeting-details {
  background-color: #fff;
  border-radius: 20px;
  padding-top: 38px;
  padding-bottom: 38px;
  padding-left: 38px;
}
.meeting-details .col-4 {
  font-weight: 500;
}
.meeting-details .row {
  margin-bottom: 18px;
}
.meeting-raised-by-st {
  background-color: #f1f1f0;
  padding: 10px 18px;
  border-radius: 8px;
  width: max-content;
}
.meeting-raised-by-st div {
  margin-bottom: 4px;
}
.align-left {
  display: flex;
  justify-content: flex-end;
}
.meeting-table {
  background-color: #fff;
  /* padding: 20px; */
  margin: 0 10px 10px 10px 10px;
  border-radius: 20px;
}
#user_table {
  cursor: pointer;
}
</style>